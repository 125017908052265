.App {
  width: 100vw;
  height: 100vh;
  background-image: url('assets/block-packaging.png'), url('assets/home-bg.png');
  background-position: calc(100% - 113px) calc(50%), center;
  background-size: 513px 537px, 100% 100%;
  background-repeat: no-repeat, no-repeat;
  padding: 100px 113px 138px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 9px;
}

.App-logo {
  height: 64px;
  width: 64px;
}

.App-title {
  font-family: MF-FangHei-Regular;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 24px;
  font-weight: 400;
}

.App-introduce {
  width: 657px;
  font-family: PingFangSC-Medium;
  font-size: 60px;
  color: #FFFFFF;
  letter-spacing: 0;
  font-weight: 500;
  margin-top: 190px;
}

.Contact-us {
  width: 185px;
  height: 56px;
  background-image: linear-gradient(206deg, #00E87D 0%, #00B5CD 95%);
  border-radius: 255px;
  cursor: pointer;
  border-width: 0;
  color: #FFFFFF;
  margin-top: 80px;
  font-family: PingFangSC-Semibold;
  font-size: 22px;
  font-weight: 600;
}

.App-describe {
  width: 581px;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 500;
  margin-top: 35px;
}

.App-community {
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
  margin-top: 63px;
}

.App-community-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.App-community-icon {
  width: 24px;
  height: 24px;
}

.App-community-name {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0;
  font-weight: 500;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
